import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { TranslationContainer } from 'react-i18n-lite'
import { ThemeProvider } from 'styled-components'
import { defaultTheme, YesErrorTracking } from '@yes.technology/react-toolkit'
import { CookiesProvider } from 'react-cookie'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import ApplicationContainer from 'infrastructure/Application/Container'
import { AuthenticationProvider } from 'authentication/shared/providers'
import locales, { defaultLanguage } from './infrastructure/i18n'

import reportWebVitals from './reportWebVitals'
import { ResizeDetector } from 'shared/components'
import { register as registerServiceWorker } from 'infrastructure/ServiceWorkerWrapper/utils/registerServiceWorker'

YesErrorTracking({
  service: 'sentry',
  publicKey: window.sentryDNS,
  traceTargets: window.trackingUrls
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const render = () =>
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={defaultTheme}>
        <QueryClientProvider client={queryClient}>
          <TranslationContainer
            defaultLanguage={defaultLanguage}
            locales={locales}
          >
            <BrowserRouter>
              <GoogleReCaptchaProvider reCaptchaKey={window.recaptchaKey}>
                <AuthenticationProvider>
                  <CookiesProvider>
                    <ResizeDetector>
                      <ApplicationContainer />
                    </ResizeDetector>
                  </CookiesProvider>
                </AuthenticationProvider>
              </GoogleReCaptchaProvider>
            </BrowserRouter>
          </TranslationContainer>
        </QueryClientProvider>
      </ThemeProvider>
    </React.StrictMode>
  )

const prepare = async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    window.location.hostname === 'localhost'
  ) {
    const { default: worker } = await import('./shared/mocks/devWorker')
    return worker.start({
      onUnhandledRequest: 'bypass'
    })
  }

  return Promise.resolve()
}

prepare().then(() => render())

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

registerServiceWorker()
