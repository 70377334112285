import { CredentialResponse, CredentialOptions } from '../types'
import { AvailableCredentials } from '../types'
import { CredentialItem, Credentials } from '../types/authentication.type'

const APPLICATIONSYSTEM_UUID = 'f355b6c5-80a9-447a-8325-0500842aac69'
const ORGANIZATIONS_UUID = '5d34636d-63fa-4972-844e-2812f01193bc'
const USER_UUID = '5508f16b-ac3c-41ce-9186-a1cb07e94698'

const defaultAvailableCredentials: AvailableCredentials = {
  user_info: [],
  organizations_info: [],
  applicationsystems_info: [],
  applicationsystem_uuids: [],
  organization_uuids: [],
  user_uuids: []
}

const parseToResponseList = (
  available_credentials: AvailableCredentials = defaultAvailableCredentials
): CredentialResponse[] => {
  const envResponse: CredentialResponse = {
    uuid: '4c1682a0-9b1c-4774-a933-1e0f2c670c6a',
    des: 'Ambiente do Ecossistema',
    options: new Array({
      id: 'Yes Ecosystem',
      value: 'Yes Ecosystem',
      label: 'Yes Ecosystem'
    })
  }
  const appResponse: CredentialResponse = {
    uuid: APPLICATIONSYSTEM_UUID,
    des: 'Sistema de Aplicações',
    options: formatOptions(available_credentials.applicationsystems_info, false)
  }
  const orgResponse: CredentialResponse = {
    uuid: ORGANIZATIONS_UUID,
    des: 'Credencial da Organização',
    options: formatOptions(available_credentials.organizations_info, true)
  }
  const userResponse: CredentialResponse = {
    uuid: USER_UUID,
    des: 'Credencial do Usuário',
    options: formatOptions(available_credentials.user_info, true)
  }
  return [envResponse, appResponse, orgResponse, userResponse]
}

export const parseToPayload = (
  selectedCredentials: Record<string, CredentialOptions>
): Credentials => {
  return {
    applicationsystem_uuid: selectedCredentials[APPLICATIONSYSTEM_UUID].value,
    organization_uuid: selectedCredentials[ORGANIZATIONS_UUID].value,
    user_uuid: selectedCredentials[USER_UUID].value
  }
}

function formatOptions(
  optionsUuids: CredentialItem[] | null | undefined,
  withSID: boolean
): CredentialOptions[] {
  if (!optionsUuids) {
    return []
  }

  return optionsUuids.map(({ uuid, sid, des }) => ({
    id: uuid,
    value: uuid,
    label: withSID ? `${sid} |  ${des}` : des
  }))
}

export default parseToResponseList
