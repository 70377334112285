import { useLocalStorage } from 'shared/hooks'

import * as api from 'authentication/shared/api'

import { UpgradeTokenRequestPayload } from 'authentication/shared/types'
import { HTTPClient } from 'infrastructure/shared/api'
import { Credentials } from '../types/authentication.type'

const useUpgradeToken = () => {
  const [authenticatedData, setAuthenticatedData] = useLocalStorage('auth')

  const upgradeToken = async (credentials?: Credentials) => {
    if (!credentials) return

    const payload = validateAndParseToUpgradeTokenPayload(
      authenticatedData instanceof Object &&
        'token' in authenticatedData &&
        typeof authenticatedData.token === 'string'
        ? authenticatedData.token
        : '',
      credentials
    )

    if (payload) {
      const response = await api.postUpgradeToken(payload)
      const upgradedToken = response?.data?.data.token

      if (upgradedToken) {
        HTTPClient.setToken(upgradedToken)
        setAuthenticatedData({
          ...(authenticatedData instanceof Object ? authenticatedData : {}),
          token: upgradedToken
        })
        return upgradedToken
      }
    }
    return
  }

  return upgradeToken
}

export default useUpgradeToken

function validateAndParseToUpgradeTokenPayload(
  token: string,
  credentials: Credentials
): UpgradeTokenRequestPayload | undefined {
  if (!validateUpgradeTokenPayload(token, credentials)) {
    return undefined
  }

  return {
    credentials,
    token: token
  }
}

export function validateUpgradeTokenPayload(
  token?: string,
  credentials?: Credentials
) {
  return (
    (token?.length || 0) > 0 &&
    !!credentials?.user_uuid &&
    !!credentials?.organization_uuid &&
    !!credentials?.applicationsystem_uuid
  )
}
