import {
  Button,
  Unifree,
  ExplicitSelectionButton,
  useParentPostMessage,
  YesObject
} from '@yes.technology/react-toolkit'
import jwtDecode from 'jwt-decode'
import { useTranslation } from 'react-i18n-lite'

import { useAuthentication } from 'authentication/shared/hooks'
import HierarchyCardWithAreaLine from './HierarchyCardWithAreaLine'
import useYesObject from 'yesObject/shared/hooks/useYesObject'
import { TokenData, User } from 'shared/types'
import {
  ActiveSessionContainer,
  ActiveSessionTitle,
  UserDataHierarchyCard
} from './styles'
import ActiveSessionLoading from './ActiveSessionLoading'
import { ProjectFields } from 'yesObject/shared/hooks/type'

const emptyCredentials: TokenData['credentials'] = {
  user_uuid: '',
  organization_uuid: '',
  applicationsystem_uuid: ''
}

const PROJECT_FIELDS: ProjectFields = {
  password: 0
}

const ActiveSession = () => {
  const { t } = useTranslation()

  const { authenticatedData } = useAuthentication()

  const token = authenticatedData?.token || ''
  const { credentials } = token
    ? jwtDecode<TokenData>(token)
    : { credentials: emptyCredentials }

  const { user_uuid, organization_uuid, applicationsystem_uuid } = credentials

  const { yesObject: user, isLoading: isLoadingUser } = useYesObject<User>({
    objectUUID: user_uuid,
    objectclassUUID: window.uuidObjectclassUser,
    fetchOnLoad: true,
    projectFields: PROJECT_FIELDS,
    showDisplayError: false
  })

  const { yesObject: organization, isLoading: isLoadingOrganization } =
    useYesObject<YesObject>({
      objectUUID: organization_uuid,
      objectclassUUID: window.uuidObjectclassOrganization,
      fetchOnLoad: true,
      showDisplayError: false
    })

  const {
    yesObject: applicationSystem,
    isLoading: isLoadingApplicationSystem
  } = useYesObject<YesObject>({
    objectUUID: applicationsystem_uuid,
    objectclassUUID: window.uuidObjectclassApplicationSystem,
    fetchOnLoad: true,
    showDisplayError: false
  })

  const { postMessage } = useParentPostMessage()

  const logout = () => {
    postMessage({ logout: true })
  }

  const modifyCredentials = () => {
    postMessage({ modifyCredentials: true })
  }

  const userIdentity = `${user?.sid || ''} | ${user?.des || ''}`

  const authenticationMethods = [
    {
      uuid: 'password',
      des: t('authentication-methods.password')
    }
  ]

  if (isLoadingUser || isLoadingOrganization || isLoadingApplicationSystem) {
    return <ActiveSessionLoading />
  }

  return (
    <ActiveSessionContainer>
      <ActiveSessionTitle>{t('active-session.title')}</ActiveSessionTitle>

      <Button heightClass='regular' onClick={logout}>
        {t('active-session.logout')}
      </Button>

      <UserDataHierarchyCard spacingClass='doubleXSmall' level={1}>
        <HierarchyCardWithAreaLine
          title={t('active-session.id-and-credentials')}
        >
          <Unifree
            informational
            required
            label={t('active-session.user-identity')}
            value={userIdentity}
          />

          <Unifree
            informational
            required
            label={t('login.identification.language')}
            value={t('language-name')}
          />
        </HierarchyCardWithAreaLine>

        <HierarchyCardWithAreaLine
          title={t('active-session.user-authentication')}
        >
          <ExplicitSelectionButton
            disabled
            selectionType='multiselection'
            heightClass='extraSmall'
            direction='vertical'
            optionLabelsPosition='right'
            options={authenticationMethods}
            checkedOption={{ password: true }}
            onChange={() => undefined}
          />
        </HierarchyCardWithAreaLine>

        <HierarchyCardWithAreaLine title={t('credentials.title')}>
          <Unifree
            informational
            required
            label={t('active-session.ecosystem-credentials')}
            value='Yes Network'
            className='mb-2'
          />
          <Unifree
            informational
            required
            label={t('active-session.applicationsystem-credential')}
            value={applicationSystem?.des || ''}
            className='mb-2'
          />
          <Unifree
            informational
            required
            label={t('active-session.organization-credential')}
            value={`${organization?.sid || ''} | ${organization?.des || ''}`}
          />
          <Unifree
            informational
            required
            label={t('active-session.user-credential')}
            value={`${user?.sid || ''} | ${user?.des || ''}`}
          />

          <Button variant='secondary' onClick={modifyCredentials}>
            {t('active-session.modify-credentials')}
          </Button>
        </HierarchyCardWithAreaLine>
      </UserDataHierarchyCard>
    </ActiveSessionContainer>
  )
}

export default ActiveSession
