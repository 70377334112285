import { useEffect, useMemo, useCallback } from 'react'

import useFetchYesObjects from './useFetchYesObjects'
import { YesObject, useDisplayUserError } from '@yes.technology/react-toolkit'
import { ProjectFields } from './type'
import { User } from 'shared/types'

type UseFetchYesObjectsArgs = {
  objectUUID: string
  objectclassUUID: string
  showLoading?: boolean
  skipRelations?: boolean
  skipMetadata?: boolean
  projectFields?: ProjectFields
  lazyFetch?: boolean
  fetchOnLoad?: boolean
  showDisplayError?: boolean
}

export default function useYesObject<YesObjectType extends YesObject>({
  objectUUID,
  objectclassUUID,
  showLoading = false,
  skipRelations = false,
  skipMetadata = false,
  projectFields,
  lazyFetch = false,
  fetchOnLoad = false,
  showDisplayError = true
}: UseFetchYesObjectsArgs) {
  const filterBody = useMemo(
    () => ({
      query: {
        $and: [
          {
            'uuid.des': {
              $eq: objectUUID
            }
          }
        ]
      },
      objectclass: objectclassUUID,
      orderby: [],
      pagination: { limit: 1, offset: 0 },
      skip_relations: skipRelations,
      skip_metadata: skipMetadata,
      project_fields: projectFields
    }),
    [objectUUID, objectclassUUID, skipRelations, projectFields, skipMetadata]
  )

  const { yesObjects, isLoading, fetchYesObjects } = useFetchYesObjects({
    filterBody,
    showLoading,
    lazy: !fetchOnLoad
  })

  const yesObject = useMemo(
    () => yesObjects[0] as YesObjectType | undefined,
    [yesObjects]
  )

  const { displayUserError } = useDisplayUserError()

  const fetchObject = useCallback(() => {
    if (objectclassUUID && objectUUID) {
      return fetchYesObjects().then((response) => {
        if (!response.data?.objects?.length) {
          showDisplayError && displayUserError({})
        }

        return response.data?.objects?.[0] as User | undefined
      })
    }
  }, [
    objectclassUUID,
    objectUUID,
    fetchYesObjects,
    showDisplayError,
    displayUserError
  ])

  useEffect(() => {
    if (!lazyFetch) {
      fetchObject()
    }
  }, [fetchObject, lazyFetch])

  return {
    yesObject,
    isLoading,
    fetchObject
  }
}
