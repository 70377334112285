import { useMemo } from 'react'
import {
  CredentialOptions,
  CredentialResponse
} from '../types/authentication.type'
import parseToResponseList, {
  parseToPayload
} from './parseAvailableCredentials'
import useAuthentication from './useAuthentication'
import useUpgradeToken from './useUpgradeToken'

import { useDisplayUserError } from '@yes.technology/react-toolkit'

const useCredentials = () => {
  const {
    authenticatedData,
    setAuthenticatedData,
    isLoading,
    startLoading,
    stopLoading
  } = useAuthentication()

  const upgradeToken = useUpgradeToken()

  const { displayUserError } = useDisplayUserError()

  const setCredential = async (
    data: { [key: string]: CredentialOptions },
    onSuccess: () => void,
    onError: (error?: unknown) => void
  ) => {
    startLoading()

    setAuthenticatedData({
      ...authenticatedData,
      credentials: data
    })

    try {
      await upgradeToken(parseToPayload(data))
      onSuccess?.()
    } catch (error) {
      onError?.(error)
      displayUserError({})
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const credentialsResponse = useMemo<CredentialResponse[]>(
    () => parseToResponseList(authenticatedData?.available_credentials),
    [authenticatedData]
  )

  return {
    credentials: credentialsResponse,
    setCredential,
    isLoadingCredential: isLoading
  }
}

export default useCredentials
