import {
  useAuthentication,
  useRedirectOrigin
} from 'authentication/shared/hooks'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'

import EnvironmentWarning from 'infrastructure/Application/components/EnvironmentWarning'

import { Router as ApplicationRouter } from './components'

import 'shared/assets/css/fonts.css'
import useHandleLanguage from 'shared/hooks/useHandleLanguage'
import { FeedbackCardContainer } from '@yes.technology/react-toolkit'
import Loading from './components/Loading'

export type ApiSystemType = { api: { isPending: boolean } }

const ApplicationContainer: React.FunctionComponent = (): JSX.Element => {
  const { isLoading } = useAuthentication()

  useRedirectOrigin()
  useHandleLanguage()

  return (
    <>
      <Loading active={isLoading} />
      <EnvironmentWarning />
      <ApplicationRouter />
      <FeedbackCardContainer />
    </>
  )
}

export default ApplicationContainer
