import { useTranslation } from 'react-i18n-lite'

import Language from '../components/Language'
import { useLoginContext } from './LoginContext'
import { Button, feedbackcard, Unifree } from '@yes.technology/react-toolkit'
import { useSessionStorage } from 'shared/hooks'
import { useState } from 'react'

type Props = {
  goToAuthentication: () => void
}

function Identification({ goToAuthentication }: Props) {
  const { t } = useTranslation()
  const { userID, setUserID, captcha } = useLoginContext()
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isUserIDInvalid, setIsUserIDInvalid] = useState(false)

  const [redirectOrigin] = useSessionStorage(
    'redirectOrigin',
    window.fallbackOrigin
  )

  const handleSignUp = () => {
    const { userRegistrationSitemodelPath } = window
    const url = new URL(redirectOrigin)
    const [subdomain] = url.hostname.split('.')
    const path =
      userRegistrationSitemodelPath[subdomain] ||
      userRegistrationSitemodelPath.default

    window.location.assign(`${url.origin}/${path}`)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!userID) {
      setIsUserIDInvalid(true)
      feedbackcard(t('login.identification.input-error.title'), {
        message: t('login.identification.input-error.message'),
        type: 'error',
        autoClose: 5000
      })

      return
    }

    setIsAuthenticating(true)
    goToAuthentication()
  }

  const onUserIDChange = (value: string) => {
    setIsUserIDInvalid(!value)
    setUserID(value)
  }

  return (
    <form
      style={{ display: 'flex', flexDirection: 'column', gap: 8 }}
      onSubmit={handleSubmit}
    >
      <Language />
      <Unifree
        label={t('login.identification.user')}
        placeholder={t('login.identification.user')}
        name='email'
        autoFocus
        value={userID}
        onChange={onUserIDChange}
        statusClass={isUserIDInvalid ? 'error' : undefined}
      />

      <Button type='submit' disabled={isAuthenticating || !captcha}>
        {t('login.identification.continue')}
      </Button>

      <Button onClick={handleSignUp} variant='secondary'>
        {t('login.identification.want-to-sign-up')}
      </Button>
    </form>
  )
}

export default Identification
